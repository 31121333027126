/* global jQuery browserReport browserReportSync */

(function ($) {
	"use strict";

	function replaceLetters(c) {
		return String.fromCharCode((c <= "Z" ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26);
	}

	$(document).ready(function () {
		var keywords;

		// provide reasonable search terms
		if (window.location.pathname) {
			keywords = window.location.pathname;

			// remove file extension
			keywords = keywords.replace(/\.\w+$/, "");

			// replace non-word characters with a space
			keywords = keywords.replace(/\W+/g, " ");

			// trim beginning and trailing whitespace
			keywords = $.trim(keywords);

			// set search field value
			$("#content #q").val(keywords);
		}

	});

	// update link to email webmaster with more information
	function updateMailtoLink(report) {
		var toEmail, title, subject, body, mailto;

		// build email parts
		toEmail = "jroznfgre@dhnylf.com";
		toEmail = toEmail.replace("jroznfgre@dhnylf", ("jroznfgre@dhnylf").replace(/[a-zA-Z]/g, replaceLetters));
		toEmail = "Qualys Webmaster <" + toEmail + ">";
		title = "Send email to " + toEmail;
		subject = "404 Page Not Found - " + document.URL;

		body = [
			"Webmaster,",
			"",
			"I encountered a broken link when accessing the following URL on your website.",
			"",
			"Requested URL: {url}",
			"Referring URL: {referrer}",
			"",
			"I'm using {browser} and here are some more details about my browser.",
			"",
			"Operating System:		{os}",
			"IP address:			{ip}",
			"Local Timestamp:		{timestamp}",
			"Preferred Language:	{lang}",
			"",
			"JavaScript Enabled:	{scripts}",
			"Cookies Enabled:		{cookies}",
			"Flash Version:			{flash}",
			"Java Version:			{java}",
			"WebSockets Supported:	{websockets}",
			"",
			"Browser Size:			{viewportWidth} x {viewportHeight} @ {zoom}%",
			"Screen Size:			{screenWidth} x {screenHeight} @ {dppx}x",
			"Color Depth:			{colors}",
			"",
			"Lastly, here is my full user agent string.",
			"",
			"{userAgent}",
			"",
			"I hope this information will help you reproduce this problem and find a solution.",
			"",
			"Sincerely,",
			""
		];

		// replace placeholder in body template with actual values
		if (body.forEach) {
			body.forEach(function (item, i, list) {
				list[i] = item.replace(/\{(\w+)\}/g, function (match) {
					var majorVersion;

					switch (match) {
					case "{url}":
						return document.URL;
					case "{referrer}":
						return document.referrer;
					case "{browser}":
						majorVersion = parseInt(report.browser.version, 10);
						return report.browser.name + " " + majorVersion;
					case "{os}":
						return report.os.name + " " + report.os.version;
					case "{ip}":
						return report.ip || "Not available";
					case "{scripts}":
						return (report.scripts) ? "Yes" : "No";
					case "{cookies}":
						return (report.cookies) ? "Yes" : "No";
					case "{flash}":
						return report.flash.version || "Not installed";
					case "{java}":
						return report.java.version || "Not installed";
					case "{websockets}":
						return (report.websockets) ? "Yes" : "No";
					case "{viewportWidth}":
						return report.viewport.width;
					case "{viewportHeight}":
						return report.viewport.height;
					case "{zoom}":
						return Math.round(report.viewport.zoom * 100);
					case "{screenWidth}":
						return report.screen.width;
					case "{screenHeight}":
						return report.screen.height;
					case "{dppx}":
						return Math.round(report.screen.dppx);
					case "{colors}":
						return report.screen.colors + " bit";
					case "{userAgent}":
						return report.userAgent;
					case "{lang}":
						if (report.lang.join) {
							report.lang = report.lang.join(", ");
						}
						return report.lang;
					case "{timestamp}":
						return report.timestamp;
					default:
						return "";
					}
				});
				list[i] = encodeURIComponent(list[i]);
			});
		}

		// join body lines with encoded CRLF line breaks
		body = body.join("%0D%0A");

		mailto = "mailto:" + toEmail + "?subject=" + encodeURIComponent(subject) + "&body=" + body;

		// set href mailto
		$("a.email-us").attr("href", mailto).attr("title", title);

	}

	$(document).ready(function () {
		var report;

		report = browserReportSync();
		updateMailtoLink(report);

		browserReport(function (err, report) {
			if (err) {
				throw err;
			}

			updateMailtoLink(report);
		});
	});

}(jQuery));
